import { FC, useCallback, useRef, useState } from 'react';
import InputBox, { messageInputId } from '../presenters/InputBox';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setIsThirdPartyDataSent, setNotificationMessage } from '../redux/widget/widgetSlice';
import { IChatHub } from '../signalr/useChatHub';
import TranslationService from '../translations/translationService';
import { OnActivateHandler } from '../utils/buttonHelper';
import { IConversationData, getThirdPartyConversationData } from '../utils/integrationHelper';

const FILE_SIZE_MESSAGE = 'File must be smaller than 10 MB';
const FILE_TYPE_NOT_SUPPORTED_MESSAGE = 'File type is not supported.';
const SINGLE_FILE_SUPPORTED_MESSAGE = 'Currently only single file upload is supported.';
const EMPTY_FILE_MESSAGE = 'You can\'t upload an empty file.';
const FILE_NAME_IS_MISSING_MESSAGE = 'File name is missing';

interface IProps {
  hub: IChatHub;
}

const InputContainer: FC<IProps> = ({ hub }) => {
  const dispatch = useAppDispatch();

  const isUploadEnabled = useAppSelector(state => state.widget.isUploadEnabled);

  const shouldSendWorkflowData = useAppSelector(state => !state.widget.isThirdPartyDataSent);
  const [cmUrl, cmApiKey] = useAppSelector(state => [state.widget.contentManagerUrl, state.widget.contentManagerApiKey]);

  const [inputValue, setInputValue] = useState('');
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const onTyping = useCallback(
    (text: string) => {
      setInputValue(text);
      hub.sendCustomerTyping();
    },
    [hub],
  );

  const sendTextMessage = useCallback(
    async () => {
      if (inputValue !== null && inputValue.trim() !== '') {
        let thirdPartyConversationData: IConversationData | undefined = undefined;

        if (shouldSendWorkflowData) {
          thirdPartyConversationData = await getThirdPartyConversationData();
          dispatch(setIsThirdPartyDataSent(true));
        }

        hub.sendCustomerMessage(inputValue, thirdPartyConversationData);
        setInputValue('');
        document.getElementById(messageInputId)?.focus();
      }
    },
    [dispatch, hub, inputValue, shouldSendWorkflowData],
  );

  const sendAttachment = useCallback(
    async (url: string, name: string, type: string) => {
      if (url && type) {
        let thirdPartyConversationData: IConversationData | undefined;

        if (shouldSendWorkflowData) {
          thirdPartyConversationData = await getThirdPartyConversationData();
          dispatch(setIsThirdPartyDataSent(true));
        }

        hub.sendCustomerAttachment({ name: name, contentUrl: url, contentType: type }, thirdPartyConversationData);
      }
    },
    [dispatch, hub, shouldSendWorkflowData],
  );


  const openFileSelector: OnActivateHandler = useCallback(
    (e) => {
      inputFileRef?.current?.click();
      e.stopPropagation();
    },
    [],
  );


  const uploadAttachment: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const files = inputFileRef?.current?.files;

      if (files && files.length > 0) {
        setIsUploadInProgress(true);
        const formData = new FormData();
        const file = files[0];
        if (file.size > 2e+7 || file.size == 0) {
          setIsUploadInProgress(false);
          dispatch(setNotificationMessage(file.size == 0 ?
            TranslationService.getTranslation('EMPTY_FILE_UPLOAD_DISABLED')
            : 'Max file size limit is 20mb',
          ));

          e.target.value = '';
          e.target.files = null;

          return;
        }
        formData.set('', file, file.name);

        fetch(`${cmUrl}attachments/webchat/upload`, {
          method: 'POST',
          headers: {
            'API-KEY': cmApiKey,
          },
          body: formData,
        })
          .then(response => {
            if (!response.ok) {
              if (response.status == 500) {
                const messageTitle = TranslationService.getTranslation('FAILED_TO_UPLOAD_FILE');
                dispatch(setNotificationMessage(messageTitle));
              }
              else {
                response.text().then(t => {
                  const messageTitle = TranslationService.getTranslation('FAILED_TO_UPLOAD_FILE');
                  let description = '';
                  if (t) {
                    switch (t) {
                      case FILE_SIZE_MESSAGE:
                        description = TranslationService.getTranslation('FILE_SIZE_MESSAGE');
                        break;
                      case FILE_TYPE_NOT_SUPPORTED_MESSAGE:
                        description = TranslationService.getTranslation('FILE_TYPE_NOT_SUPPORTED_MESSAGE');
                        break;
                      case SINGLE_FILE_SUPPORTED_MESSAGE:
                        description = TranslationService.getTranslation('SINGLE_FILE_SUPPORTED_MESSAGE');
                        break;
                      case EMPTY_FILE_MESSAGE:
                        description = TranslationService.getTranslation('EMPTY_FILE_MESSAGE');
                        break;
                      case FILE_NAME_IS_MISSING_MESSAGE:
                        description = TranslationService.getTranslation('FILE_NAME_IS_MISSING_MESSAGE');
                        break;
                      default:
                        description = TranslationService.getTranslation('UNKNOWN_FILE_UPLOAD_ERROR');
                        break;
                    }
                    dispatch(setNotificationMessage(`${messageTitle}: ${description}`));
                  }
                  else {
                    dispatch(setNotificationMessage(`${messageTitle}: ${response.statusText}`));
                  }
                });

              }
              setIsUploadInProgress(false);
            }
            else {
              return response;
            }
          })
          .then(
            response => response?.text().then(
              data => {
                sendAttachment(data, file.name, file.type);
                setIsUploadInProgress(false);
              },
            ),
          )
          .catch(
            () => {
              setIsUploadInProgress(false);
              dispatch(setNotificationMessage(TranslationService.getTranslation('FAILED_TO_UPLOAD_FILE')));
            },
          );
      }

      e.target.value = '';
      e.target.files = null;

      e.stopPropagation();
    },
    [cmUrl, cmApiKey, dispatch, sendAttachment],
  );

  return (<InputBox
    isUploadEnabled={isUploadEnabled}
    isUploadInProgress={isUploadInProgress}
    inputValue={inputValue}
    onChange={onTyping}
    onSubmit={sendTextMessage}
    inputFileRef={inputFileRef}
    uploadAttachment={openFileSelector}
    inputFileAddedHandler={uploadAttachment} />);
};

export default InputContainer;