import { getItem, setItem } from '@buzzeasy/shared-frontend-utilities';

export const getOrCreateItem = <T>(key: string, defaultValue: T): T => {
  let item = getItem<T>(key);

  if (item === null) {
    item = defaultValue;
    setItem(key, item);
  }

  return item;
};
