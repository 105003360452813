import { addAttentionHookMessage, addProactiveMessage, updateProactiveMessageDisplayState } from '../redux/chat/chatSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { IProactiveMessage } from '../models/signalR/WidgetConfiguration';
import { usePureEffect } from '@buzzeasy/shared-frontend-utilities';

interface WebChatIntegrationInterface {
  sendProactiveMessage(args: IProactiveMessage): void;
  openWidget(): void;
  attentionHooks: {
    displayMessage(args: { text: string }): void;
  }
}

export default function useHandleIntegration(isInitialized: boolean, channelProactiveMessageTimeout: NodeJS.Timeout | undefined, setIsWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>) {
  const dispatch = useAppDispatch();
  const hubState = useAppSelector(s => s.widget.connectionStatus);

  // setup integration
  usePureEffect(
    (_dispatch) => {
      if (!isInitialized)
        return;

      const integrationInterface: WebChatIntegrationInterface = {
        sendProactiveMessage: (args) => {
          if (hubState === 'initial') {
            if (channelProactiveMessageTimeout)
              clearTimeout(channelProactiveMessageTimeout);

            _dispatch(addProactiveMessage(args));
            _dispatch(updateProactiveMessageDisplayState(args));
          }
        },
        openWidget: () => {
          setIsWidgetOpen(true);
        },
        attentionHooks: {
          displayMessage: (args) => {
            _dispatch(addAttentionHookMessage(args));
          },
        },
      };

      Object.assign(window, { buzzeasyWebChat: integrationInterface });
      window.dispatchEvent(new Event('buzzeasyWebChat-integrationLoaded'));
    },
    [channelProactiveMessageTimeout, hubState, isInitialized, setIsWidgetOpen],
    dispatch,
  );
}
