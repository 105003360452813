import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHeaderUpdate } from '../../models/signalR/HeaderUpdate';
import { IWidgetSettings } from '../../models/signalR/WidgetConfiguration';
import { notificationSoundLocalStorage } from '../../utils/webChatConstants';
import { getOrCreateItem } from '../../utils/localStorageHelpers';

export interface IWidgetSetup {
  isUploadEnabled: boolean,
}

export interface IHeaderData {
  title: string,
  subtitle: string,
  avatarUrl: string,
}

export type ConnectionStatus = 'initial' | 'connecting' | 'connected' | 'reconnecting' | 'disconnected';

export interface WidgetState {
  headerData: IHeaderData,
  isThirdPartyDataSent: boolean,
  isUploadEnabled: boolean;
  isMenuOpen: boolean,
  connectionStatus: ConnectionStatus,
  contentManagerUrl: string,
  contentManagerApiKey: string,
  notificationMessage?: string,
  disableTranscriptDownload: boolean;
  privacyPolicyUrl?: string
  notificationSound: boolean;
}

const initialState: WidgetState = {
  headerData: { title: '', avatarUrl: '', subtitle: '' },
  isThirdPartyDataSent: false,
  isMenuOpen: false,
  connectionStatus: 'initial',
  isUploadEnabled: false,
  contentManagerApiKey: 'no-cm-key',
  contentManagerUrl: 'no-cm-url',
  disableTranscriptDownload: false,
  notificationSound: getOrCreateItem<boolean>(notificationSoundLocalStorage, true),
};

const widgetSlice = createSlice({
  initialState,
  name: 'widget',
  reducers: {
    setupWidget(state, { payload }: PayloadAction<IWidgetSettings>) {
      state.connectionStatus = 'initial';
      state.contentManagerApiKey = payload.contentManagerApiKey;
      state.contentManagerUrl = payload.contentManagerUrl;
      state.isUploadEnabled = payload.isUploadEnabled;
      state.headerData = {
        avatarUrl: payload.defaultAvatarUrl,
        subtitle: payload.defaultDisplayName,
        title: payload.defaultTitle,
      };
      state.disableTranscriptDownload = payload.disableTranscriptDownload;
      state.privacyPolicyUrl = payload.privacyPolicyUrl;
    },
    setIsThirdPartyDataSent(state, { payload }: PayloadAction<boolean>) {
      state.isThirdPartyDataSent = payload;
    },
    setHeaderData(state, { payload }: PayloadAction<IHeaderUpdate>) {
      state.headerData = {
        title: payload.headerText,
        subtitle: payload.displayName,
        avatarUrl: payload.avatarUrl,
      };
    },
    setConnectionStatus(state, { payload }: PayloadAction<ConnectionStatus>) {
      state.connectionStatus = payload;
    },
    setNotificationMessage(state, action: PayloadAction<string | undefined>) {
      state.notificationMessage = action.payload;
    },
    setMenuState(state, { payload }: PayloadAction<boolean>) {
      state.isMenuOpen = payload;
    },
    toggleNotificationSound(state) {
      state.notificationSound = !state.notificationSound;
    },
  },
});

export const {
  setupWidget,
  setIsThirdPartyDataSent,
  setHeaderData,
  setConnectionStatus,
  setNotificationMessage,
  setMenuState,
  toggleNotificationSound,
} = widgetSlice.actions;
export default widgetSlice.reducer;