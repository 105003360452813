import { IAttachment } from './signalR/Attachment';

interface IMessageBase {
  key: string,
  from: ParticipantType,
  date: string,
}

export interface ITextMessage extends IMessageBase {
  type: MessageType.Text,
  text: string,
}

export interface IAttachmentMessage extends IMessageBase {
  type: MessageType.Attachment,
  attachment: IAttachment,
}

export interface ISuggestedActionsMessage extends IMessageBase {
  type: MessageType.SuggestedAction,
  disabled: boolean,
  suggestedActions: string[],
}

export interface IDividerMessage extends IMessageBase {
  type: MessageType.Divider,
  text: string,
}

export interface ITypingMessage extends IMessageBase {
  type: MessageType.Typing,
}

export interface ILoadingMessage extends IMessageBase {
  type: MessageType.Loading,
  text?: string,
}

export type IChatMessage =
  ITextMessage |
  IAttachmentMessage |
  ISuggestedActionsMessage |
  IDividerMessage |
  ITypingMessage |
  ILoadingMessage;

export interface IPromotionalMessage {
  text: string;
  date: string;
  suggestedActions: string[];
}

export interface IAttentionHookMessage {
  text: string;
  date: string;
}

export enum MessageType {
  Text,
  Attachment,
  Divider,
  Typing,
  Loading,
  SuggestedAction,
}

export enum ParticipantType {
  Queue = 'Queue',
  Agent = 'Agent',
  Bot = 'Bot',
  Customer = 'Customer',
  System = 'System',
}